import { createSlice } from '@reduxjs/toolkit';

import { init } from '../resource';

import { getConversationsQuota } from './conversationsQuotaThunks';
import { ConversationsQuota } from './model';

export const conversationsQuotaSlice = createSlice({
  name: 'conversationsQuota',
  initialState: init<ConversationsQuota>(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getConversationsQuota.pending,
      (state, { meta: { requestId } }) => {
        state.request = 'loading';
        state.currentRequestId = requestId;
      },
    );
    builder.addCase(
      getConversationsQuota.fulfilled,
      (state, { meta: { requestId }, payload }) => {
        if (state.currentRequestId === requestId) {
          state.value = payload;
          state.request = 'idle';
          state.currentRequestId = undefined;
        }
      },
    );
    builder.addCase(
      getConversationsQuota.rejected,
      (state, { meta: { requestId } }) => {
        if (state.currentRequestId === requestId) {
          state.request = 'error';
          state.currentRequestId = undefined;
        }
      },
    );
  },
});

export const { reducer: conversationsQuotaReducer } = conversationsQuotaSlice;
