import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FlagsState = {
  isContact: boolean;
  subscribedToDuplexer: boolean;
};

const initialState: FlagsState = {
  isContact: true,
  subscribedToDuplexer: false,
};

export const flagsSlice = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    setIsContact: (state, { payload }: PayloadAction<boolean>) => {
      state.isContact = payload;
    },
    setSubscribedToDuplexer: (state) => {
      state.subscribedToDuplexer = true;
    },
  },
});

export const selectFlags = {
  isContact: (state: { flags: FlagsState }) => state.flags.isContact,
  isSubscribedToDuplexer: (state: { flags: FlagsState }) =>
    state.flags.subscribedToDuplexer,
};

export const { reducer: flagsReducer } = flagsSlice;
