import React, { useMemo } from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { isStudioSite } from '@/utils';

import settingsParams from 'AiAssistantWidget/settingsParams';

import { st, classes, cssStates } from './Container.st.css';
import { ContainerDataHooks } from './ContainerDataHooks';

type ContainerProps = {
  children: React.ReactNode;
  ariaBusy?: boolean;
};

export const Container = ({ children, ariaBusy }: ContainerProps) => {
  const { isEditor, isMobile } = useEnvironment();
  const settings = useSettings();

  const isLeftLayout = useMemo(
    () => settings.get(settingsParams.layout) === 'left',
    [settings],
  );

  const noAdsBanner = isEditor || (isMobile && !isStudioSite());

  return (
    <div
      className={st(
        classes.root,
        cssStates({
          left: isLeftLayout,
          hasShadow: settings.get(settingsParams.applyShadow),
          isEditor,
          isMobile,
        }),
      )}
      // TODO: check why settings `top` doesn't work in `st.css` file
      style={noAdsBanner ? {} : { top: 'var(--wix-ads-height)' }}
      data-hook={ContainerDataHooks.ROOT}
      aria-busy={Boolean(ariaBusy)}
    >
      <div className={st(classes.wrapper, classes.overscrollHack)}>
        {children}
      </div>
    </div>
  );
};
