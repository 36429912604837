// * Hack to avoid widget collapse on change of placement in editor.
//    * Details: https://github.com/wix-private/wix-ai-assistant-bot/issues/162
//    * Originally made by Tkachenko in controller.ts
import { useEffect } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { expandWidget } from '@/features';
import { useAppDispatch } from '@/store';

import settingsParams from 'AiAssistantWidget/settingsParams';

export function useForcedExpandInEditor() {
  const dispatch = useAppDispatch();
  const { isEditor } = useEnvironment();
  const settings = useSettings();

  useEffect(() => {
    if (isEditor) {
      const SETTINGS_forceExpandedStateInEditor = (settings.get(
        settingsParams.forceExpandedStateInEditor,
      ) ?? 0) as number;
      const SESSION_reactedOnForceExpand = +JSON.parse(
        sessionStorage.getItem('reactedOnForceExpand') ?? '0',
      );
      if (SETTINGS_forceExpandedStateInEditor === 0) {
        sessionStorage.removeItem('reactedOnForceExpand');
      } else if (
        SETTINGS_forceExpandedStateInEditor !== SESSION_reactedOnForceExpand
      ) {
        sessionStorage.setItem(
          'reactedOnForceExpand',
          `${SETTINGS_forceExpandedStateInEditor}`,
        );
        dispatch(expandWidget());
      }
    }
  }, [dispatch, isEditor, settings]);
}
