import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSettings, useStyles } from '@wix/tpa-settings/react';
import {
  IWixSDKContext,
  useEnvironment,
  useWixSdk,
} from '@wix/yoshi-flow-editor';

import { useAppSelector } from '@/store';
import { selectLayout } from '@/features';

import stylesParams from 'AiAssistantWidget/stylesParams';
import settingsParams from 'AiAssistantWidget/settingsParams';
import { SettingsMinimizedButtonLayout } from 'AiAssistantWidget/Settings/components';

type ResizeWindowDeprecatedWixSDK = {
  resizeWindow: (width: number, height: number, callback?: () => void) => void;
};
type WixSDK = (IWixSDKContext['Wix'] & ResizeWindowDeprecatedWixSDK) | null;

const MINIMIZED_WIDTH_FALLBACK = 300;
const MINIMIZED_HEIGHT_FALLBACK = 100;
const MINIMIZED_WIDTH_EXTRA_SPACE = 50;
const MINIMIZED_HEIGHT_EXTRA_SPACE = 20;

const SHADOW_EXTRA_SPACE = 60;

export const useResizeWidgetWindow = () => {
  const styles = useStyles();
  const settings = useSettings();
  const { Wix } = useWixSdk() as { Wix: WixSDK };
  const { isMobile, isEditorX: isStudio, isEditor } = useEnvironment();
  const isStudioMobile = isStudio && isMobile;
  const isClassicEditor = isEditor && !isStudio;
  const isClassicMobile = isEditor && isMobile && !isStudio;
  const hasShadow = settings.get(settingsParams.applyShadow);

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [refWidth, setRefWidth] = useState<number>(0);
  const [refHeight, setRefHeight] = useState<number>(0);

  const isOpened = useAppSelector(selectLayout.visual) === 'visible';

  const positionHeightFix = useMemo(() => {
    const position = isStudioMobile
      ? 'icon'
      : (settings.get(
          settingsParams.minimizedButtonPosition,
        ) as SettingsMinimizedButtonLayout);
    switch (position) {
      case 'icon':
        return isClassicEditor ? 15 : 20;
      case 'floating':
        return 20;
      case 'sticky':
        return 0;
      default:
        return 0;
    }
  }, [settings, isStudioMobile, isClassicEditor]);

  const widgetWidth = useMemo(
    () =>
      styles.get(stylesParams.widgetWidth) +
      (hasShadow ? SHADOW_EXTRA_SPACE : 0),
    [styles, hasShadow],
  );
  const minimizedWidth = useMemo(() => {
    if (!refWidth) {
      return MINIMIZED_WIDTH_FALLBACK;
    }

    return refWidth + MINIMIZED_WIDTH_EXTRA_SPACE;
  }, [refWidth]);

  const getWidgetHeight = useCallback(
    (fullHeight: number) => {
      if (isMobile) {
        return fullHeight;
      }

      return Math.round(
        (fullHeight * styles.get(stylesParams.widgetHeight)) / 100,
      );
    },
    [styles, isMobile],
  );

  const getWidgetWidth = useCallback(() => {
    if (isStudioMobile && isOpened) {
      return Wix?.Settings.setFullWidth(true);
    }
    return isOpened ? widgetWidth : minimizedWidth;
  }, [isStudioMobile, isOpened, Wix, widgetWidth, minimizedWidth]);

  const minimizedHeight = useMemo(() => {
    if (!refHeight) {
      return MINIMIZED_HEIGHT_FALLBACK;
    }

    return refHeight + MINIMIZED_HEIGHT_EXTRA_SPACE + positionHeightFix;
  }, [refHeight, positionHeightFix]);

  useEffect(() => {
    if (!isEditor || isClassicMobile || !Wix) {
      return;
    }

    Wix?.getBoundingRectAndOffsets(({ offsets, rect }) => {
      Wix?.resizeWindow(
        getWidgetWidth(),
        isOpened ? getWidgetHeight(offsets.y + rect.height) : minimizedHeight,
      );
    });
  }, [
    isOpened,
    isEditor,
    widgetWidth,
    minimizedHeight,
    Wix,
    isClassicMobile,
    getWidgetHeight,
    getWidgetWidth,
    hasShadow,
  ]);

  useEffect(() => {
    if (!ref) {
      setRefWidth(0);
      setRefHeight(0);
      return;
    }

    const callback = () => {
      setRefWidth(ref.offsetWidth);
      setRefHeight(ref.offsetHeight);
    };

    const o = new ResizeObserver(callback);
    o.observe(ref);

    return () => {
      o.disconnect();
    };
  }, [ref]);

  return { setRef, isOpened };
};
