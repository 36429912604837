import { VisualLayoutState } from '@/features';
import { SS_VISUAL_LAYOUT_STATE } from '@/constants';

import { RootState } from '../store';

const initialVisibleState = {
  layout: {
    visual: VisualLayoutState.Visible,
    wasOpened: true,
  },
} as const;

type InitialStateBuilderParams = {
  initialLayoutOpenProp?: boolean;
  isEditor: boolean;
};

export const initialStateBuilder = ({
  isEditor,
  initialLayoutOpenProp,
}: InitialStateBuilderParams): Partial<RootState> | undefined => {
  if (isEditor) {
    if (initialLayoutOpenProp) {
      return initialVisibleState;
    }
    return;
  }

  if (
    sessionStorage.getItem(SS_VISUAL_LAYOUT_STATE) === VisualLayoutState.Visible
  ) {
    return initialVisibleState;
  }

  return undefined;
};
