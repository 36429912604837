import React from 'react';

export const AIAvatarIcon: React.FC = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 6C20.7383 6 20.9435 6.16823 20.9903 6.40194L21.0981 6.94078C21.296 7.93043 22.0696 8.70401 23.0592 8.90194L23.5981 9.00971C23.8318 9.05645 24 9.26166 24 9.5C24 9.73834 23.8318 9.94355 23.5981 9.99029L23.0592 10.0981C22.0696 10.296 21.296 11.0696 21.0981 12.0592L20.9903 12.5981C20.9435 12.8318 20.7383 13 20.5 13C20.2617 13 20.0565 12.8318 20.0097 12.5981L19.9019 12.0592C19.704 11.0696 18.9304 10.296 17.9408 10.0981L17.4019 9.99029C17.1682 9.94355 17 9.73834 17 9.5C17 9.26166 17.1682 9.05645 17.4019 9.00971L17.9408 8.90194C18.9304 8.70401 19.704 7.93043 19.9019 6.94078L20.0097 6.40194C20.0565 6.16823 20.2617 6 20.5 6Z"
      fill="currentColor"
    />
    <path
      d="M13.5 9C13.7255 9 13.9231 9.15091 13.9824 9.36844L14.4299 11.0094C15.0355 13.2298 16.7702 14.9645 18.9906 15.5701L20.6316 16.0176C20.8491 16.0769 21 16.2745 21 16.5C21 16.7255 20.8491 16.9231 20.6316 16.9824L18.9906 17.4299C16.7702 18.0355 15.0355 19.7702 14.4299 21.9906L13.9824 23.6316C13.9231 23.8491 13.7255 24 13.5 24C13.2745 24 13.0769 23.8491 13.0176 23.6316L12.5701 21.9906C11.9645 19.7702 10.2298 18.0355 8.00939 17.4299L6.36844 16.9824C6.15091 16.9231 6 16.7255 6 16.5C6 16.2745 6.15091 16.0769 6.36844 16.0176L8.00939 15.5701C10.2298 14.9645 11.9645 13.2298 12.5701 11.0094L13.0176 9.36844C13.0769 9.15091 13.2745 9 13.5 9Z"
      fill="currentColor"
    />
  </svg>
);
