import React from 'react';

import { Card } from 'wix-ui-tpa';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { ChatCard } from '../ChatCard';
import { PayloadCmpProps } from '../ChatMessagePayload/types';

import { st, classes } from './FormSubmittedPayload.st.css';

type FormSubmittedPayloadProps = PayloadCmpProps & {
  email?: string;
  name?: string;
  phone?: string;
};

export const FormSubmittedPayload = ({
  isSmall,
  email,
  name,
  phone,
}: FormSubmittedPayloadProps) => {
  const { t } = useTranslation();
  return (
    <ChatCard className={st(classes.root)} isSmall={isSmall}>
      <Card.Container>
        {name && <p>{`${t('app.widget.form.name')}: ${name}`}</p>}
        <p>{`${t('app.widget.form.email')}: ${email}`}</p>
        {phone && <p>{`${t('app.widget.form.phone')}: ${phone}`}</p>}
      </Card.Container>
    </ChatCard>
  );
};
