import React, { useEffect, useCallback, useMemo } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  selectSettings,
  selectFlags,
  getEditorMockMessages,
  subscribeForNewMessages,
  selectMessages,
  getConversationId,
  DummyMessageType,
  getConversationsQuotaThrottled,
  selectShouldShowWidget,
  getSettings,
  expandWidget,
  minimizeWidget,
} from '@/features';
import {
  usePresenceClient,
  useResizeWidgetWindow,
  useForcedExpandInEditor,
  useForcedExpandInViewer,
} from '@/hooks';
import { isStudioSite } from '@/utils';

import {
  ChatVisibility,
  settingsEvents,
} from 'AiAssistantWidget/settingsEvents';

import {
  useOnEditorSettingsEvent,
  useOnEditorSettingsReset,
} from '../../contexts';
import { MinimizedChat } from '../MinimizedChat';
import { Chat } from '../Chat';

export interface ChatDataWrapperProps {}

export const ChatWrapper: React.FC<ChatDataWrapperProps> = () => {
  const dispatch = useAppDispatch();
  const { isEditor, isMobile, isEditorX: isStudioEditor } = useEnvironment();
  const shouldGetConversationId = useAppSelector(
    selectMessages.shouldGetConversationId,
  );
  const conversationId = useAppSelector(selectMessages.conversationId);
  const neverLoadedSettings = useAppSelector(selectSettings.neverLoaded);
  const shouldShowWidget = useAppSelector(selectShouldShowWidget);
  const isSubscribedToDuplexer = useAppSelector(
    selectFlags.isSubscribedToDuplexer,
  );
  const { setRef, isOpened } = useResizeWidgetWindow();

  const shouldShowMinimizedWidget = useMemo(() => {
    /**
     * On mobile, websites, created in Classic Editor uses
     * Quick Action Bar component to render minimized state,
     * so we don't render minimized Widget at all.
     *
     * In Studio we do render.
     */

    if (isMobile && !isStudioSite() && !isStudioEditor) {
      return false;
    }

    return !isOpened;
  }, [isOpened, isMobile, isStudioEditor]);

  usePresenceClient();

  useOnEditorSettingsEvent(
    settingsEvents.visual,
    useCallback(
      (state) => {
        if (!isEditor) {
          return;
        }
        switch (state) {
          case ChatVisibility.Visible:
            dispatch(expandWidget());
            break;
          case ChatVisibility.Minimized:
            dispatch(minimizeWidget());
            break;
        }
      },
      [dispatch, isEditor],
    ),
  );

  useForcedExpandInViewer();

  useOnEditorSettingsReset(() => dispatch(minimizeWidget()), []);

  useForcedExpandInEditor();

  useOnEditorSettingsEvent(
    settingsEvents.openMessages,
    useCallback(() => {
      if (!isEditor) {
        return;
      }

      dispatch(expandWidget());
      dispatch(getEditorMockMessages());
    }, [dispatch, isEditor]),
  );
  useOnEditorSettingsEvent(
    settingsEvents.openVisitorMessages,
    useCallback(() => {
      if (!isEditor) {
        return;
      }
      dispatch(expandWidget());
      dispatch(getEditorMockMessages(DummyMessageType.Visitor));
    }, [dispatch, isEditor]),
  );
  useOnEditorSettingsEvent(
    settingsEvents.openAssistantMessages,
    useCallback(() => {
      if (!isEditor) {
        return;
      }
      dispatch(expandWidget());
      dispatch(getEditorMockMessages(DummyMessageType.Assistant));
    }, [dispatch, isEditor]),
  );
  useOnEditorSettingsEvent(
    settingsEvents.openCardMessages,
    useCallback(() => {
      if (!isEditor) {
        return;
      }
      dispatch(expandWidget());
      dispatch(getEditorMockMessages(DummyMessageType.Card));
    }, [dispatch, isEditor]),
  );
  useEffect(() => {
    if (isEditor) {
      dispatch(getEditorMockMessages());
    }
  }, [dispatch, isEditor]);

  useEffect(() => {
    // Settings are needed in Editor as well for displaying minimized widget defferences
    // https://github.com/wix-private/wix-ai-assistant-bot/issues/758
    if (neverLoadedSettings) {
      dispatch(getSettings());
    }

    if (isEditor) {
      return;
    }

    if (shouldGetConversationId) {
      dispatch(getConversationId());
      return; // no need to subscribe for new messages if we don't have conversationId
    }

    if (!isSubscribedToDuplexer && conversationId) {
      dispatch(subscribeForNewMessages());
    }
  }, [
    isEditor,
    neverLoadedSettings,
    isSubscribedToDuplexer,
    shouldGetConversationId,
    conversationId,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getConversationsQuotaThrottled());
  }, [dispatch]);

  // TODO: this if should be covered by tests in Editor and Live site env
  if (!isEditor && !shouldShowWidget) {
    return null;
  }

  return (
    <>
      {/* Read note at EditorWrapper file */}
      {/* <EditorWrapper> } */}
      {isOpened && <Chat />}
      {shouldShowMinimizedWidget && <MinimizedChat setRef={setRef} />}
      {/* </EditorWrapper> */}
    </>
  );
};
