export function assertNonNullable<T>(
  val: T,
  castError:
    | ((val: undefined | null) => Error)
    | ((val: undefined | null) => string) = () =>
    new Error('expected NonNullable'),
): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    const error = castError(val as null | undefined);
    if (typeof error === 'string') {
      throw new Error(error);
    }
    throw error;
  }
}
