import {
  createEntityAdapter,
  createSlice,
  isAnyOf,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import {
  Message as _APIMessage,
  ListMessagesRequest,
  PagingMetadataV2,
} from '@wix/ambassador-innovation-widget-v1-message/types';

import {
  getMessagesHistory,
  getEditorMockMessages,
  getConversationId,
} from './messagesThunks';

export type APIMessage = _APIMessage;
export type Message = Omit<
  APIMessage,
  'createdDate' | 'text' | 'suffixText' | 'structPayloads'
> & {
  id: string;
  createdDate?: number;
};

export const messagesAdapter = createEntityAdapter<Message>({
  sortComparer: (a, b) => (a.createdDate ?? 0) - (b.createdDate ?? 0),
});

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesAdapter.getInitialState<{
    request: 'idle' | 'loading' | 'loadingHistory' | 'error';
    conversationIdRequest: 'idle' | 'loading';
    pendingMessages: number;
    currentRequestId?: string;
    isTyping: boolean;
    hasUnread: boolean;
    pagingMetadata: PagingMetadataV2 | null;
    conversationId: string | null;
  }>({
    request: 'idle',
    conversationIdRequest: 'idle',
    pendingMessages: 0,
    isTyping: false,
    hasUnread: false,
    pagingMetadata: null,
    conversationId: null,
  }),
  reducers: {
    replace: messagesAdapter.setAll,
    addMessage: messagesAdapter.addOne,
    resetConversation: (state, { payload }: PayloadAction<string>) => {
      state.pendingMessages = 0;
      state.request = 'idle';
      state.currentRequestId = undefined;
      state.isTyping = false;
      state.hasUnread = false;
      state.pagingMetadata = null;
      state.conversationId = payload;
    },
    addOptimisticMessage: (state, { payload }: PayloadAction<Message>) => {
      state.pendingMessages++;
      messagesAdapter.addOne(state, payload);
    },
    resetMessages: (
      state,
      {
        payload: { messages, pagingMetadata },
      }: PayloadAction<{
        pagingMetadata?: PagingMetadataV2;
        messages: Message[];
      }>,
    ) => {
      messagesAdapter.setAll(state, messages);
      state.pagingMetadata = pagingMetadata ?? null;
    },
    addMessages: (
      state,
      {
        payload,
      }: PayloadAction<{
        pagingMetadata?: PagingMetadataV2;
        messages: Message[];
      }>,
    ) => {
      messagesAdapter.addMany(state, payload.messages);
      state.pagingMetadata = payload.pagingMetadata!;
    },
    updateMessage: (
      state,
      { payload }: PayloadAction<Update<Message, string>>,
    ) => {
      // Message has got an id means it's no longer an optimistic
      if (
        payload.id &&
        payload.changes.id &&
        payload.id !== payload.changes.id &&
        state.pendingMessages > 0 // In case someone uses addMessage instead of addOptimisticMessage
      ) {
        state.pendingMessages--;
      }
      messagesAdapter.updateOne(state, payload);
    },
    addOrUpdateMessage: messagesAdapter.upsertOne,
    removeMessage: messagesAdapter.removeOne,
    setConversationId: (state, { payload }: PayloadAction<string>) => {
      state.conversationId = payload;
    },
    setIsTyping: (
      state,
      { payload }: PayloadAction<boolean | null | undefined>,
    ) => {
      state.isTyping = !!payload;
    },
    setUnread: (state, { payload }: PayloadAction<boolean>) => {
      state.hasUnread = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConversationId.pending, (state) => {
      state.conversationIdRequest = 'loading';
    });
    builder.addCase(getConversationId.fulfilled, (state) => {
      state.conversationIdRequest = 'idle';
    });
    builder.addMatcher(
      isAnyOf(getMessagesHistory.pending, getEditorMockMessages.pending),
      (state, action) => {
        const isHistoryRequest = Boolean(
          (action.meta.arg as ListMessagesRequest)?.cursorPaging?.cursor,
        );
        state.currentRequestId = action.meta.requestId;
        state.request = isHistoryRequest ? 'loadingHistory' : 'loading';
      },
    );
    builder.addMatcher(
      isAnyOf(getMessagesHistory.fulfilled, getEditorMockMessages.fulfilled),
      (state, action) => {
        if (state.currentRequestId === action.meta.requestId) {
          state.currentRequestId = undefined;
          state.request = 'idle';
        }
      },
    );
  },
});

export const { reducer: messagesReducer } = messagesSlice;
