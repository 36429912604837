import React from 'react';

import { ThreeDotsLoader } from 'wix-ui-tpa';

import { ChatMessageWrapper } from '../ChatMessageWrapper';

import { st, classes } from './Loader.st.css';

export const Loader: React.FC = () => (
  <ChatMessageWrapper className={st(classes.root)}>
    <ThreeDotsLoader className={st(classes.loader)} />
  </ChatMessageWrapper>
);
