import React from 'react';

type SendIconProps = {
  className?: string;
};
export const SendIcon = ({ className }: SendIconProps) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.23119 7.66875C6.04753 7.20234 4.87572 8.32884 5.29877 9.52868C5.93442 11.3315 6.68216 13.3846 7 14L14.5389 14.7013C14.9018 14.735 14.9018 15.2649 14.5389 15.2987L7 16C6.69101 16.5985 5.94075 18.6585 5.30183 20.4684C4.87812 21.6687 6.05048 22.7965 7.23471 22.3299C11.7154 20.5643 19.5204 17.4706 23.4089 15.9286C24.2506 15.5948 24.2506 14.4052 23.4089 14.0714C19.5194 12.5289 11.7113 9.43407 7.23119 7.66875Z"
      fill="currentColor"
    />
  </svg>
);
