import React, { useMemo } from 'react';

import { Card, Badge, BadgePriority } from 'wix-ui-tpa';

import { MessageType } from '@wix/ambassador-innovation-widget-v1-message/types';
import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  senderIsAssistant,
  senderIsOwner,
  senderIsVisitor,
} from '@/utils/sender';
import { useAppSelector } from '@/store';
import { Message, selectFlags } from '@/features';

import { iconsDict } from 'AiAssistantWidget/Settings/components';
import settingsParams from 'AiAssistantWidget/settingsParams';
import { useRootProps } from 'AiAssistantWidget/Widget/contexts';

import { AIAvatarIcon, AvatarIcon } from '../svg';
import { ChatMessageWrapper } from '../ChatMessageWrapper';
import { ChatMessagePayload } from '../ChatMessagePayload';
import { ContactForm } from '../ContactForm';
import { ContactFormDataHooks } from '../ContactForm/ContactFormDataHooks';
import { Markdown } from '../Markdown';

import { st, classes, cssStates } from './ChatMessage.st.css';

export interface ChatMessageProps {
  message?: Message;
  last?: boolean;
}

export const ChatMessage = ({ message, last = false }: ChatMessageProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { avatar: memberAvatar } = useRootProps();
  const isContact = useAppSelector(selectFlags.isContact);

  const isStaffMessage = !senderIsVisitor(message?.sender);

  const aria = useMemo(() => {
    if (!message) {
      return {};
    }
    const { sender } = message;

    if (senderIsAssistant(sender)) {
      return {
        'aria-labelledby': t('app.widget.ariallabel.answer', {
          name: settings.get(settingsParams.textHeader),
        }),
      };
    }

    if (senderIsOwner(sender)) {
      return {
        'aria-label': t('app.widget.ariallabel.intervention'),
      };
    }

    if (senderIsVisitor(sender)) {
      return {
        'aria-label': t('app.widget.ariallabel.question'),
      };
    }
    return {};
  }, [t, message, settings]);

  const icon = useMemo(() => {
    if (!isStaffMessage) {
      return memberAvatar ? (
        <img
          className={st(classes.memberAvatar)}
          src={memberAvatar}
          alt={t('app.widget.chat.userPicAltText')}
        />
      ) : (
        <AvatarIcon />
      );
    }
    const iconKey = settings.get(settingsParams.minimizedButtonIcon);
    return iconKey ? iconsDict[iconKey] : <AIAvatarIcon />;
  }, [isStaffMessage, settings, memberAvatar, t]);

  return (
    <ChatMessageWrapper
      className={last ? st(classes.lastMessage) : ''}
      {...aria}
    >
      <Card
        className={st(
          classes.root,
          cssStates({
            aiAnswer: isStaffMessage,
            applyAiAnswerShadow:
              isStaffMessage &&
              settings.get(settingsParams.applyAssistantMessageShadow),
            userQuestion: !isStaffMessage,
            applyUserQuestionBackground:
              !isStaffMessage &&
              settings.get(settingsParams.applyVisitorMessageBackground),
            applyUserQuestionShadow:
              !isStaffMessage &&
              settings.get(settingsParams.applyVisitorMessageShadow),
          }),
        )}
      >
        <Card.Container className={st(classes.container)}>
          <div
            className={st(
              classes.icon,
              cssStates({ isUserMessage: !isStaffMessage }),
            )}
          >
            {icon}
          </div>
          <div className={st(classes.content)}>
            {senderIsOwner(message?.sender) && (
              <div className={st(classes.badgeContainer)}>
                <Badge priority={BadgePriority.secondary}>
                  {t('app.widget.badge.stuffRespond')}
                </Badge>
              </div>
            )}
            {message && ( // TODO: make better matching message types
              <>
                {message.messageType === MessageType.CONTACT_FORM_SUBMITTED && (
                  <p data-hook={ContactFormDataHooks.SUBMITTED_MESSAGE}>
                    {t('app.widget.form.submitResponse')}
                  </p>
                )}

                {!!message.body?.mainText?.trim().length && (
                  <Markdown
                    className={st(classes.text)}
                    text={message.body.mainText}
                  />
                )}
                <ChatMessagePayload message={message} />

                {Boolean(message.body?.footerText) && (
                  <Markdown
                    className={st(classes.suffixText)}
                    text={message.body!.footerText!}
                  />
                )}

                {message.messageType === MessageType.CONTACT_FORM &&
                  !isContact && <ContactForm formId={message.id} />}
              </>
            )}
          </div>
        </Card.Container>
      </Card>
    </ChatMessageWrapper>
  );
};
