import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store';

import { calculateUsageStatus, defaultQuota, UsageStatus } from './model';

export const quota = (state: RootState) => state.conversationsQuota;
export const quotaValue = createSelector(quota, ({ request, value }) => {
  if (!value && request === 'error') {
    return defaultQuota();
  }
  return value;
});

export const isLoaded = createSelector(quotaValue, (value) => value !== null);

export const shouldLoad = createSelector(
  quota,
  ({ request }) => request !== 'loading',
);

export const usageStatus = createSelector(quotaValue, (quota) => {
  const current = quota?.current;
  const limit = quota?.quota;

  if (limit === undefined || current === undefined) {
    return undefined;
  }

  return calculateUsageStatus(current, limit);
});

export const isExceeding = createSelector(
  usageStatus,
  (usageStatus) => usageStatus && usageStatus === UsageStatus.Exceeding,
);
