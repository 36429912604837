import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes, cssStates } from './ChatMessageWrapper.st.css';

export const ChatMessageWrapper: React.FC<
  React.HTMLAttributes<HTMLElement>
> = ({ children, ...attrs }) => {
  const { isMobile } = useEnvironment();
  return (
    <div
      {...attrs}
      className={st(classes.root, attrs.className, cssStates({ isMobile }))}
    >
      <div className={st(classes.inner)}>{children}</div>
    </div>
  );
};
