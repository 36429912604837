// Simplified phone validation
// max possible phone number length should be 15digits + codes and brackets adds to a total length of 31 char
// Valid:
// Shortest valid: 1111
// Longest valid: +(111) 1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1
// +111 11-11-111
// (111) 11.11.111
// 111 11 11 111
// +(111)11-11-111-11
// 1111111
// TODO: find proper solution with country code
export function isPhoneValidSimplified(s?: string) {
  return s && /^\+?(\(\d{1,3}\)[ .-]?)?(\d[ .-]?){3,17}\d$/.test(s);
}
