import React, { useCallback, useMemo, useState } from 'react';

import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa';
import { TextField, TextArea } from 'wix-ui-tpa/cssVars';
import { EmailValidator } from 'commons-validator-js';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { selectSettings, submitVisitorAsContact } from '@/features';
import { useAppDispatch, useAppSelector } from '@/store';
import { isPhoneValidSimplified } from '@/utils';

import { ChatCard } from '../ChatCard';

import { st, classes } from './ContactForm.st.css';
import { ContactFormDataHooks } from './ContactFormDataHooks';

export interface FormData {
  email: string;
  name?: string;
  phone?: string;
  message?: string;
  formId: string;
}

type ContactFormProps = {
  formId: string;
};

export const ContactForm = ({ formId }: ContactFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contactFormFields = useAppSelector(selectSettings.contactFormFields);
  const [formData, setFormData] = useState<FormData>({ email: '', formId });
  const emailValidator = useMemo(() => new EmailValidator(), []);
  const isEmailValid = useMemo(
    () => emailValidator.isValid(formData.email),
    [formData.email, emailValidator],
  );
  const isPhoneValid = useMemo(
    () => isPhoneValidSimplified(formData.phone),
    [formData.phone],
  );

  const onSubmit = useCallback(
    (ev) => {
      ev.preventDefault();
      dispatch(submitVisitorAsContact(formData));
    },
    [dispatch, formData],
  );

  return (
    <ChatCard
      className={st(classes.root)}
      data-hook={ContactFormDataHooks.ROOT}
    >
      <form onSubmit={onSubmit}>
        {contactFormFields?.name && (
          <>
            <TextField
              className={st(classes.input)}
              label={t('app.widget.form.name')}
              value={formData.name}
              onChange={(ev) =>
                setFormData({ ...formData, name: ev.target.value })
              }
            />
            <br />
          </>
        )}

        <TextField
          className={st(classes.input)}
          label={t('app.widget.form.email')}
          value={formData.email}
          onChange={(ev) =>
            setFormData({ ...formData, email: ev.target.value })
          }
          newErrorMessage
          error={Boolean(formData.email) && !isEmailValid}
          errorMessage={t('app.widget.form.email-validation-error')}
          required
        />

        {contactFormFields?.phone && (
          <>
            <br />
            <TextField
              className={st(classes.input)}
              label={t('app.widget.form.phone')}
              value={formData.phone}
              onChange={(ev) =>
                setFormData({ ...formData, phone: ev.target.value })
              }
              newErrorMessage
              error={Boolean(formData.phone) && !isPhoneValid}
              errorMessage={t('app.widget.form.phone-validation-error')}
            />
          </>
        )}

        {contactFormFields?.message && (
          <>
            <br />
            <TextArea
              className={st(classes.textArea)}
              label={t('app.widget.form.message')}
              value={formData.message ?? ''}
              ariaLabel="test"
              onChange={(ev) =>
                setFormData({ ...formData, message: ev.target.value })
              }
            />
          </>
        )}

        <br />
        <Button
          type="submit"
          className={st(classes.button, { disabled: !isEmailValid })}
          upgrade
          fullWidth
          priority={ButtonPriority.primary}
          disabled={!isEmailValid}
          size={ButtonSize.tiny}
        >
          {t('app.widget.form.submit')}
        </Button>
      </form>
    </ChatCard>
  );
};
