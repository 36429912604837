import * as QuotaApi from '@wix/ambassador-innovation-assistant-v1-quota/http';
import { QuotaType } from '@wix/ambassador-innovation-assistant-v1-quota/types';

import { createAppAsyncThunk } from '../createAppAsyncThunk';

import { toConversationsQuota } from './model';
import { shouldLoad as selectShouldLoad } from './conversationsQuotaSelectors';

export const getConversationsQuota = createAppAsyncThunk(
  'chat/getConversationsQuota',
  async (_, { extra: { httpClient: client } }) => {
    const {
      data: { quota },
    } = await client.request(
      QuotaApi.getQuota({ types: [QuotaType.CONVERSATIONS] }),
    );

    return toConversationsQuota({
      quota: quota?.[0].quota ?? undefined,
      current: quota?.[0].current ?? undefined,
    });
  },
);

export const getConversationsQuotaThrottled = createAppAsyncThunk(
  'chat/getConversationsQuota/throttled',
  (_, { dispatch, getState }) => {
    const shouldLoad = selectShouldLoad(getState());

    if (shouldLoad) {
      return dispatch(getConversationsQuota());
    }
  },
);
