import React, { useCallback, useEffect, useMemo } from 'react';

import { useSettingsEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  ColorPicker,
  FontAndColorPicker,
  OpacityColorPicker,
  SectionHeader,
  SliderLabeled,
  useSettings,
  useStyles,
} from '@wix/tpa-settings/react';
import {
  Divider,
  Composites,
  TextLabel,
  Thumbnails,
  ThumbnailsProps,
  RadioButtons,
  ToggleSwitch,
  AngleInput,
} from '@wix/wix-base-ui';
import { ISettingsColor } from '@wix/tpa-settings';
import { IFontAndColorPickerValue } from '@wix/tpa-settings/dist/src/components/FontAndColorPicker/FontAndColorPicker.types';

import LayoutFloatingIcon from '@/assets/icons/minimized_button/layout_floating.svg';
import LayoutIconOnlyIcon from '@/assets/icons/minimized_button/layout_icon.svg';
import LayoutStickyIcon from '@/assets/icons/minimized_button/layout_sticky.svg';
import mobileIcons from '@/assets/icons/minimized_button/mobile/index.json';
import ChatBotIcon from '@/assets/icons/minimized_button/ChatBot.svg';
import ChatStarIcon from '@/assets/icons/minimized_button/ChatStar.svg';
import Frame420Icon from '@/assets/icons/minimized_button/Frame_420.svg';
import OneStarIcon from '@/assets/icons/minimized_button/OneStar.svg';
import TwoStarsIcon from '@/assets/icons/minimized_button/TwoStars.svg';

import stylesParams from 'AiAssistantWidget/stylesParams';
import settingsParams from 'AiAssistantWidget/settingsParams';
import {
  ChatVisibility,
  settingsEvents,
} from 'AiAssistantWidget/settingsEvents';
import { useSettingsEventPublish } from 'AiAssistantWidget/Settings/useSettingsEventPublish';

import { useIsMobile } from '../useIsMobile';
import { BreakpointsBanner } from '../BreakpointsBanner';

import { st, classes } from './DesignMinimizedButton.st.css';

export type SettingsMinimizedButtonLayout = 'floating' | 'sticky' | 'icon';
export type SettingsMinimizedButtonDisplay = 'textIcon' | 'text' | 'icon';

export const icons = [
  {
    value: 'ai',
    illustration: <Frame420Icon />,
    mobile: mobileIcons.Frame420Icon,
  },
  {
    value: 'magic-1',
    illustration: <TwoStarsIcon />,
    mobile: mobileIcons.TwoStarsIcon,
  },
  {
    value: 'magic-2',
    illustration: <OneStarIcon />,
    mobile: mobileIcons.OneStarIcon,
  },
  {
    value: 'magic-3',
    illustration: <ChatStarIcon />,
    mobile: mobileIcons.ChatStarIcon,
  },
  {
    value: 'robot',
    illustration: <ChatBotIcon />,
    mobile: mobileIcons.ChatBotIcon,
  },
];

export const iconsDict = icons.reduce((acc, el) => {
  acc[el.value] = el.illustration;
  return acc;
}, {} as Record<string, React.JSX.Element>);

export const mobileIconsDict = icons.reduce((acc, el) => {
  acc[el.value] = el.mobile;
  return acc;
}, {} as Record<string, string>);

export const DesignMinimizedButton = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const styles = useStyles();
  const { isWixStudio } = useSettingsEnvironment();
  const isMobile = useIsMobile();

  const layout = settings.get(
    settingsParams.minimizedButtonPosition,
  ) as SettingsMinimizedButtonLayout;
  const display = settings.get(
    settingsParams.minimizedButtonDisplay,
  ) as SettingsMinimizedButtonDisplay;

  const thumbnails = useMemo<
    ThumbnailsProps<SettingsMinimizedButtonLayout>['options']
  >(
    () => [
      {
        value: 'floating',
        illustration: <LayoutFloatingIcon />,
        label: t('app.settings.design.button.floating'),
      },
      {
        value: 'sticky',
        illustration: <LayoutStickyIcon />,
        label: t('app.settings.design.button.sticky'),
      },
      {
        value: 'icon',
        illustration: <LayoutIconOnlyIcon />,
        label: t('app.settings.design.button.icon'),
      },
    ],
    [t],
  );

  const displayOptions = useMemo<
    { value: SettingsMinimizedButtonDisplay; label: string }[]
  >(
    () => [
      {
        value: 'textIcon',
        label: t('app.settings.design.button.displayTextIconLabel'),
      },
      {
        value: 'text',
        label: t('app.settings.design.button.displayTextLabel'),
      },
      {
        value: 'icon',
        label: t('app.settings.design.button.displayIconLabel'),
      },
    ],
    [t],
  );

  const onChangeLayout = useCallback(
    (v: string) => {
      settings.set(settingsParams.minimizedButtonPosition, v);

      switch (v) {
        case 'sticky':
          styles.set(stylesParams.minimizedButtonRadius, 8);
          break;
        default:
          styles.set(
            stylesParams.minimizedButtonRadius,
            styles.getDefaultValue(stylesParams.minimizedButtonRadius),
          );
          break;
      }
    },
    [settings, styles],
  );

  const onChangeDisplay = useCallback(
    (v: string) => {
      settings.set(settingsParams.minimizedButtonDisplay, v);
    },
    [settings],
  );

  const onChangeBackground = useCallback(
    (c: ISettingsColor) => {
      styles.set(stylesParams.minimizedButtonBackground, c);
    },
    [styles],
  );

  const onChangeRadius = useCallback(
    (v: number) => {
      styles.set(stylesParams.minimizedButtonRadius, v);
    },
    [styles],
  );

  const onChangeFontAndColor = useCallback(
    ({ font, color }: IFontAndColorPickerValue) => {
      styles.set(stylesParams.minimizedButtonFont, font);
      styles.set(stylesParams.minimizedButtonFontColor, color);
    },
    [styles],
  );

  const onChangeIconColor = useCallback(
    (c: ISettingsColor) => {
      styles.set(stylesParams.minimizedButtonIconColor, c);
    },
    [styles],
  );

  const onChangeIcon = useCallback(
    (icon: string) => {
      settings.set(settingsParams.minimizedButtonIcon, icon);
    },
    [settings],
  );

  const onToggleAnimation = useCallback(
    (value: boolean) => {
      settings.set(settingsParams.minimizedButtonNudgeAnimation, value);
    },
    [settings],
  );

  const onToggleApplyShadow = useCallback(
    (value: boolean) => {
      settings.set(settingsParams.minimizedButtonShadow, value);
    },
    [settings],
  );

  const onShadowColorChange = useCallback(
    (c: ISettingsColor) => {
      styles.set(stylesParams.minimizedButtonShadowColor, c);
    },
    [styles],
  );

  const onChangeShadowBlur = useCallback(
    (value: number) => {
      styles.set(stylesParams.minimizedButtonShadowBlur, value);
    },
    [styles],
  );

  const onChangeShadowSpread = useCallback(
    (value: number) => {
      styles.set(stylesParams.minimizedButtonShadowSpread, value);
    },
    [styles],
  );

  const onChangeShadowAngle = useCallback(
    (value: number) => {
      styles.set(stylesParams.minimizedButtonShadowDirection, value);
    },
    [styles],
  );

  const publishEvent = useSettingsEventPublish();
  useEffect(() => {
    publishEvent(settingsEvents.visual(ChatVisibility.Minimized));
  }, [publishEvent]);

  return (
    <div className={st(classes.root)}>
      <BreakpointsBanner />
      <SectionHeader
        text={t('app.settings.design.customize.minimized')}
        hideTopDivider={isWixStudio}
      />
      {!isMobile && (
        <Composites.Thumbnails className={st(classes.layoutPicker)}>
          <TextLabel value={t('app.settings.design.chooseThemeLabel')} />
          <Thumbnails
            options={thumbnails}
            value={settings.get(settingsParams.minimizedButtonPosition)}
            className="letters-thumbnails big"
            onChange={onChangeLayout}
          />
        </Composites.Thumbnails>
      )}
      <Divider long />
      {!isMobile && layout !== 'icon' && (
        <>
          <Composites.RadioButtonsLabeled>
            <TextLabel value={t('app.settings.design.button.display')} />
            <RadioButtons
              value={display}
              options={displayOptions}
              onChange={onChangeDisplay}
            />
          </Composites.RadioButtonsLabeled>
          <Divider long />
        </>
      )}
      <ColorPicker
        title={t('app.settings.general.backgroundColorLabel')}
        onChange={onChangeBackground}
        value={styles.get(stylesParams.minimizedButtonBackground)}
      />
      <Divider long />
      <SliderLabeled
        label={t('app.settings.design.button.radiusLabel')}
        value={styles.get(stylesParams.minimizedButtonRadius)}
        onChange={onChangeRadius}
        max={40}
        min={0}
        unit="px"
      />
      <Divider long />
      {!isMobile && display !== 'icon' && layout !== 'icon' && (
        <>
          <FontAndColorPicker
            title={t('app.settings.design.button.textLabel')}
            fontPickerOptions={{ fontMinSize: 14, fontMaxSize: 20 }}
            value={{
              font: styles.get(stylesParams.minimizedButtonFont),
              color: styles.get(stylesParams.minimizedButtonFontColor),
            }}
            onChange={onChangeFontAndColor}
            panelTitle={t('app.settings.design.button.textLabel')}
          />
          <Divider long />
        </>
      )}
      {(display !== 'text' || isMobile) && (
        <>
          <ColorPicker
            title={t('app.settings.design.button.iconColorLabel')}
            onChange={onChangeIconColor}
            value={styles.get(stylesParams.minimizedButtonIconColor)}
          />
          <Divider long />
          <Composites.Thumbnails>
            <TextLabel
              value={t('app.settings.design.button.chooseIconLabel')}
            />
            <Thumbnails
              options={icons}
              value={settings.get(settingsParams.minimizedButtonIcon)}
              className="letters-thumbnails small"
              onChange={onChangeIcon}
              maxThumbsPerRow={5}
              noneThumbnailProps={
                isMobile
                  ? undefined
                  : {
                      value: 'none',
                    }
              }
              fixedRatio
            />
          </Composites.Thumbnails>
          <Divider long />
        </>
      )}
      <Composites.ToggleSwitch>
        <ToggleSwitch
          label={t('app.settings.design.button.animationLabel')}
          value={settings.get(settingsParams.minimizedButtonNudgeAnimation)}
          onChange={onToggleAnimation}
        />
      </Composites.ToggleSwitch>
      <Divider long />
      <Composites.ToggleSwitch>
        <ToggleSwitch
          label={t('app.settings.general.shadowLabel')}
          value={settings.get(settingsParams.minimizedButtonShadow)}
          onChange={onToggleApplyShadow}
        />
      </Composites.ToggleSwitch>
      {settings.get(settingsParams.minimizedButtonShadow) && (
        <>
          <OpacityColorPicker
            title={t('app.settings.design.button.opacityColorLabel')}
            onChange={onShadowColorChange}
            value={styles.get(stylesParams.minimizedButtonShadowColor)}
          />
          <Divider long />
          <SliderLabeled
            label={t('app.settings.design.button.shadowBlurLabel')}
            value={styles.get(stylesParams.minimizedButtonShadowBlur)}
            onChange={onChangeShadowBlur}
            max={60}
            min={0}
            unit="px"
          />
          <Divider long />
          <SliderLabeled
            label={t('app.settings.design.button.shadowSpread')}
            value={styles.get(stylesParams.minimizedButtonShadowSpread)}
            onChange={onChangeShadowSpread}
            max={30}
            min={0}
            unit="px"
          />
          <Divider long />
          <Composites.AngleInputLabeled>
            <TextLabel
              value={t('app.settings.design.button.shadowDirection')}
            />
            <AngleInput
              value={styles.get(stylesParams.minimizedButtonShadowDirection)}
              onChange={onChangeShadowAngle}
            />
          </Composites.AngleInputLabeled>
        </>
      )}
    </div>
  );
};
