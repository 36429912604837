import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IconButton, IconButtonThemes } from 'wix-ui-tpa';

import { ChevronLeft, ChevronRight } from '@wix/wix-ui-icons-common/on-stage';

import { st, classes, cssStates } from './ScrollingGallery.st.css';

type ScrollingGalleryProps = {
  arrowPosition?: number | 'center';
  children: React.ReactNode;
  arialLabel?: string;
};

export const ScrollingGallery = ({
  arrowPosition = 'center',
  children,
  arialLabel,
}: ScrollingGalleryProps) => {
  const arrowOffset = useMemo(
    () => (arrowPosition === 'center' ? '50%' : `${arrowPosition}px`),
    [arrowPosition],
  );

  const [arrowState, setArrowState] = useState({ left: false, right: true });

  const scroller = useRef<HTMLDivElement>(null);

  const onClickArrow = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      switch (e.currentTarget.name) {
        case 'left': {
          scroller.current?.scrollBy({
            left: -scroller.current.offsetWidth,
            behavior: 'smooth',
          });
          break;
        }
        case 'right': {
          scroller.current?.scrollBy({
            left: scroller.current.offsetWidth,
            behavior: 'smooth',
          });
          break;
        }
      }
    },
    [],
  );

  useLayoutEffect(() => {
    const onScroll = () => {
      if (!scroller.current) {
        return;
      }

      const { scrollLeft, scrollWidth, offsetWidth } = scroller.current;

      setArrowState({
        left: scrollLeft > 0,
        right: scrollLeft + offsetWidth < scrollWidth,
      });
    };

    scroller.current?.addEventListener('scroll', onScroll);

    return () => {
      scroller.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={st(classes.root)} aria-label={arialLabel}>
      <IconButton
        className={st(
          classes.arrow,
          classes.arrowLeft,
          cssStates({ visible: arrowState.left }),
        )}
        style={{ top: arrowOffset }}
        theme={IconButtonThemes.Box}
        icon={<ChevronLeft />}
        name="left"
        onClick={onClickArrow}
      />

      <div className={st(classes.scroller)} ref={scroller}>
        <div className={st(classes.flexLayoutSizeFix)}>
          <div className={st(classes.scrollerItems)}>{children}</div>
        </div>
      </div>

      <IconButton
        className={st(
          classes.arrow,
          classes.arrowRight,
          cssStates({ visible: arrowState.right }),
        )}
        style={{ top: arrowOffset }}
        theme={IconButtonThemes.Box}
        icon={<ChevronRight />}
        name="right"
        onClick={onClickArrow}
      />
    </div>
  );
};
