export interface Resource<T> {
  value: T | null;
  request: 'idle' | 'loading' | 'error';
  currentRequestId?: string;
}

export const init = <T>(value?: T): Resource<T> => ({
  value: value ?? null,
  request: 'idle',
  currentRequestId: undefined,
});
