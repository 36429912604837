import React from 'react';

import { ErrorBoundary } from './ErrorBoundary';

interface ErrorBoundaryProps {
  renderError: (error: any) => React.ReactNode;
  onCatch?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

export function withErrorBoundary<Props>(
  Component: React.FC<Props>,
  renderError: (error: any) => React.ReactNode,
): React.FC<Props>;
export function withErrorBoundary<Props>(
  Component: React.FC<Props>,
  errorBoundaryProps: ErrorBoundaryProps,
): React.FC<Props>;
export function withErrorBoundary<Props>(
  Component: React.FC<Props>,
  propsOrRender: ErrorBoundaryProps | ((error: any) => React.ReactNode),
): React.FC<Props> {
  return (props) => {
    if (typeof propsOrRender === 'function') {
      const renderError = propsOrRender;
      return (
        <ErrorBoundary renderError={renderError}>
          <Component {...props} />
        </ErrorBoundary>
      );
    }
    const errorBoundaryProps = propsOrRender;
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}
