import React, { useEffect, useState } from 'react';

import type { Options } from 'react-markdown';

import { st, classes } from './Markdown.st.css';

const DummyMarkdown: React.FC<{ className?: string }> = (props) => {
  return <div className={props.className}>{props.children}</div>;
};

/**
 * Match URLs in a string.
 *
 * This regex identifies URLs that:
 * - Start with "http://" or "https://".
 * - Optionally include "www.".
 * - Contain a valid domain name with 2 to 256 characters.
 * - End with a top-level domain (TLD) of 2 to 63 characters.
 * - May include additional valid URL path, query, or fragment components.
 *
 * Exclusions:
 * - URLs enclosed in parentheses are not matched.
 *
 * Example matches:
 * - "https://example.com"
 * - "http://www.example.com/path?query=123"
 *
 * Example non-matches:
 * - "(https://example.com)"
 * - "example.com" (missing protocol)
 */
const URL_REGEX =
  /(?<!\()https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)(?!\))/g;

export const Markdown: React.FC<{
  text: string;
  className?: string;
}> = ({ text, className }) => {
  const [remarkBreaks, setRemarkBreaks] = useState<() => void>();

  useEffect(() => {
    if (!remarkBreaks) {
      import('remark-breaks').then((module) => {
        setRemarkBreaks(() => module.default);
      });
    }
  }, [remarkBreaks]);

  const [rehypeMinifyWhitespace, setRehypeMinifyWhitespace] =
    useState<() => void>();

  useEffect(() => {
    if (!rehypeMinifyWhitespace) {
      import('rehype-minify-whitespace').then((module) => {
        setRehypeMinifyWhitespace(() => module.default);
      });
    }
  }, [rehypeMinifyWhitespace]);

  const [ReactMarkdown, setReactMarkdown] =
    useState<React.ComponentType<Options> | null>(null);

  useEffect(() => {
    if (!ReactMarkdown) {
      import('react-markdown').then((module) => {
        setReactMarkdown(() => module.default);
      });
    }
  }, [ReactMarkdown]);

  if (!ReactMarkdown || !remarkBreaks || !rehypeMinifyWhitespace) {
    return <DummyMarkdown className={className}>{text}</DummyMarkdown>;
  }

  return (
    <div className={className} data-hook="Markdown">
      <ReactMarkdown
        className={st(classes.root)}
        remarkPlugins={[remarkBreaks]}
        rehypePlugins={[rehypeMinifyWhitespace]}
      >
        {text.replaceAll(URL_REGEX, (u) => {
          return `[${u}](${u})`;
        })}
      </ReactMarkdown>
    </div>
  );
};
