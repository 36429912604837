import { createSelector } from '@reduxjs/toolkit';

import { MessageType } from '@wix/ambassador-innovation-widget-v1-message/types';

import { RootState } from '@/store';
import { senderIsAnswer } from '@/utils/sender';

import { MESSAGES_MAX_FOR_INTRO, MESSAGES_PAGE_SIZE } from './config';
import { messagesAdapter } from './messagesSlice';

const adapterSelectors = messagesAdapter.getSelectors<RootState>(
  (state) => state.messages,
);

export const entities = adapterSelectors.selectEntities;
export const ids = adapterSelectors.selectIds;
export const total = adapterSelectors.selectTotal;

export const value = (state: RootState) => state.messages;

const _list = adapterSelectors.selectAll;
export const formSubmissions = createSelector(_list, (l) =>
  l.reduce((acc, m) => {
    if (m.messageType === MessageType.CONTACT_FORM_SUBMITTED && m.answerTo) {
      acc.add(m.answerTo);
    }
    return acc;
  }, new Set<string>()),
);

export const visibleMessages = createSelector(formSubmissions, _list, (f, l) =>
  l.filter((m) => !f.has(m.id)),
);

export const request = createSelector(value, (v) => v.request);
export const isTyping = createSelector(value, (v) => v.isTyping);
export const hasUnread = createSelector(value, (v) => v.hasUnread);
export const pagingMetadata = createSelector(value, (v) => v.pagingMetadata);
export const conversationId = createSelector(value, (v) => v.conversationId);
export const pendingMessages = createSelector(value, (v) => v.pendingMessages);

export const isIdle = createSelector(request, (r) => r === 'idle');
export const isLoading = createSelector(request, (r) => r === 'loading');
export const isLoadingHistory = createSelector(
  request,
  (r) => r === 'loadingHistory',
);

export const isGetConversationIdIdle = createSelector(
  value,
  (v) => v.conversationIdRequest === 'idle',
);

export const shouldGetConversationId = createSelector(
  conversationId,
  isGetConversationIdIdle,
  (c, idle) => !c && idle,
);

export const isError = createSelector(request, (r) => r === 'error');

export const lastAnswer = createSelector(_list, (l) => {
  const last = l.at(-1);
  return senderIsAnswer(last?.sender) ? last : undefined;
});

export const lastUnread = createSelector(
  hasUnread,
  lastAnswer,
  (unread, answer) => (unread ? answer : undefined),
);

export const shouldLoadMessages = createSelector(
  pagingMetadata,
  isIdle,
  (metadata, idle) => metadata === null && idle,
);

export const isLoaded = createSelector(
  pagingMetadata,
  (metadata) => metadata !== null,
);

export const loadingFirstPage = createSelector(
  isLoaded,
  isLoading,
  (loaded, loading) => !loaded && loading,
);

export const shouldSendIntro = createSelector(
  isLoaded,
  _list,
  total,
  (loaded, l, t) =>
    loaded &&
    t <= MESSAGES_MAX_FOR_INTRO &&
    !l.find((i) => i.messageType === MessageType.INTRO),
);
export const shouldSendLegal = createSelector(
  isLoaded,
  _list,
  total,
  (loaded, l, t) =>
    loaded &&
    t <= MESSAGES_MAX_FOR_INTRO &&
    !l.find((i) => i.messageType === MessageType.LEGAL),
);

export const shouldSendOfflineMessage = createSelector(
  isLoaded,
  _list,
  total,
  (loaded, l, t) =>
    loaded &&
    t <= MESSAGES_MAX_FOR_INTRO &&
    !l.find((i) => i.messageType === MessageType.OFFLINE),
);

export const noContactFormOnLastPage = createSelector(
  isLoaded,
  _list,
  (loaded, l) =>
    loaded &&
    !l
      .slice(-MESSAGES_PAGE_SIZE)
      .find((i) => i.messageType === MessageType.CONTACT_FORM),
);
