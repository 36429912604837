import React, { useContext } from 'react';

import { INavigateToPageOptions } from '@wix/yoshi-flow-editor';

export type NavigateTo = (opts: INavigateToPageOptions) => void;
export type NavigateToUrl = (path: string) => void;

export type SiteNavigation = {
  baseUrl: string;
  navigateTo: NavigateTo;
  navigateToUrl: NavigateToUrl;
};

export const SiteNavigationContext = React.createContext<SiteNavigation>({
  baseUrl: '',
  navigateTo: () => {},
  navigateToUrl: () => {},
});

export const SiteNavigationProvider: React.FC<{
  navigation: SiteNavigation;
}> = ({ navigation, children }) => {
  return (
    <SiteNavigationContext.Provider value={navigation}>
      {children}
    </SiteNavigationContext.Provider>
  );
};

export function useSiteNavigation() {
  return useContext(SiteNavigationContext);
}
