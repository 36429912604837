export interface SettingsEvent<Data = void> {
  readonly name: string;
  readonly data: Data;
}

export interface SettingsEventDefinition<Data = void> {
  readonly eventName: string;
  (data: Data): SettingsEvent<Data>;
}

const defineEvent = <Data = void>(
  name: string,
): SettingsEventDefinition<Data> => {
  const create = (data: Data) => ({ name, data });
  create.eventName = name;
  return create;
};

export const enum ChatVisibility {
  Visible = 'visible',
  Minimized = 'minimized',
}
const visual = defineEvent<ChatVisibility>('visual');

const openMessages = defineEvent('openMessages');
const openVisitorMessages = defineEvent('openVisitorMesages');
const openAssistantMessages = defineEvent('openAssistantMessages');
const openCardMessages = defineEvent('openCardMessages');

export const settingsEvents = {
  visual,
  openMessages,
  openVisitorMessages,
  openAssistantMessages,
  openCardMessages,
};
