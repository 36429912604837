import { useMemo } from 'react';

import {
  createHttpClient,
  IHttpClient,
  useEnvironment,
} from '@wix/yoshi-flow-editor';

export function useHttpClient(instance?: string): IHttpClient {
  const { isSSR } = useEnvironment();

  return useMemo(
    () =>
      createHttpClient({
        isSSR,
        getAppToken: () => instance ?? '',
      }),
    [instance, isSSR],
  );
}
