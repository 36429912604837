import { mapper } from '../models';

export interface ConversationsQuota {
  quota?: number;
  current?: number;
}

export const defaultQuota = (): ConversationsQuota => ({
  current: 0,
  quota: Infinity,
});

export const toConversationsQuota = mapper<ConversationsQuota>;

export const enum UsageStatus {
  Normal = 'normal',
  Approaching = 'approaching',
  Exceeding = 'exceeding',
}

// Defined at
// https://github.com/wix-private/wix-vmr-repo/blob/6b9105f54d3b67149f6c9501c39517a37174351a/innovation-lab/wix-assistant/wix-assistant-producer/proto/wix/innovation/assistant/producer/v1/site.proto#L78
export const enum PackageType {
  Unknown = 'UNKNOWN_PACKAGE',
  Free = 'FREE',
  Essential = 'ESSENTIAL',
  Core = 'CORE',
  Advanced = 'ADVANCED',
  Beta = 'BETA',
  Custom = 'CUSTOM',
}

export const calculateRate = (current: number, limit: number) => {
  return (100 * current) / limit;
};

export const calculateUsageStatus = (
  current: number,
  limit: number,
): UsageStatus => {
  const rate = calculateRate(current, limit);
  if (rate >= 100) {
    return UsageStatus.Exceeding;
  }
  if (rate >= 85) {
    return UsageStatus.Approaching;
  }

  return UsageStatus.Normal;
};
