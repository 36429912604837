import React from 'react';

interface ErrorBoundaryProps {
  renderError: (error: any) => React.ReactNode;
  onCatch?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { error?: Error }
> {
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { onCatch } = this.props;
    if (onCatch) {
      onCatch(error, errorInfo);
    } else {
      console.error(error, errorInfo);
    }
  }

  render() {
    const { renderError, children } = this.props;
    const { error } = this.state;
    if (error) {
      // You can render any custom fallback UI
      return renderError(error);
    }

    return children;
  }
}
