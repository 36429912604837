import React from 'react';

export const AvatarIcon: React.FC = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="14" fill="#D8D8D8" />
    <g clipPath="url(#clip0_3558_4400)">
      <path
        d="M19.5999 10.7101C19.5999 13.8029 17.0927 16.3101 13.9999 16.3101C10.9071 16.3101 8.39994 13.8029 8.39994 10.7101C8.39994 7.61731 10.9071 5.11011 13.9999 5.11011C17.0927 5.11011 19.5999 7.61731 19.5999 10.7101Z"
        fill="#969696"
      />
      <path
        d="M22.2121 20.435C24.5135 22.3645 26.062 25.0429 26.5859 28.0001H1.41394C1.93787 25.0429 3.48638 22.3645 5.78782 20.435C8.08926 18.5055 10.9966 17.4479 13.9999 17.4479C17.0032 17.4479 19.9106 18.5055 22.2121 20.435Z"
        fill="#969696"
      />
    </g>
    <defs>
      <clipPath id="clip0_3558_4400">
        <rect width="28" height="28" rx="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
