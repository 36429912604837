import React from 'react';

import { ButtonPriority, ButtonSize, PaddingModes } from 'wix-ui-tpa';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { withErrorBoundary } from '@/errors';
import { assertNonNullable } from '@/errors/assertions';

import { PayloadCmpProps } from '../ChatMessagePayload/types';
import { LinkButton } from '../ButtonLink';

import { classes, st } from './PagePayload.st.css';

type PagePayloadProps = PayloadCmpProps & {
  image?: string;
  title?: string;
  link?: string;
};

export const PagePayload: React.FC<
  PagePayloadProps & React.HTMLAttributes<HTMLElement>
> = withErrorBoundary(
  ({ isSmall, image, title, link, ...rootElementAttrs }) => {
    assertNonNullable(
      link,
      () => new MissingLinkError(`Page payload with no link! ${title} ${link}`),
    );

    const { t } = useTranslation();

    return (
      <div
        className={`${st(classes.root)} ${rootElementAttrs.className}`}
        aria-label={t('app.widget.ariallabel.page')}
      >
        <LinkButton
          priority={ButtonPriority.secondary}
          title={title ?? link}
          link={link}
          size={ButtonSize.tiny}
          // This prop is needed for ellipsis behavior
          paddingMode={PaddingModes.explicitPaddings}
        />
      </div>
    );
  },
  (error) => {
    if (error instanceof MissingLinkError) {
      console.error(error.message);
      return null;
    }
    throw error;
  },
);

class MissingLinkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MissingLinkError';
  }
}
