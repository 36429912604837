import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetWidgetSettingsResponse as _APIGetWidgetSettingsResponse,
  WidgetSettings as _APIWidgetSettings,
} from '@wix/ambassador-innovation-widget-v1-widget-settings/types';

import { getSettings } from './settingsThunks';

export type APIWidgetSettings = _APIWidgetSettings;
export type APIGetWidgetSettingsResponse = _APIGetWidgetSettingsResponse;

export type WidgetSettings = Omit<
  APIWidgetSettings,
  'createdDate' | 'updatedDate'
> & {
  createdDate?: number;
  updatedDate?: number;
};

export type GetWidgetSettingsResponse = Omit<
  APIGetWidgetSettingsResponse,
  'widgetSettings'
> & {
  widgetSettings: WidgetSettings;
};

export type SettingsState = {
  value: GetWidgetSettingsResponse | null;
  request: 'idle' | 'loading' | 'error';
  currentRequestId?: string;
};

const initialState: SettingsState = {
  value: null,
  request: 'idle',
  currentRequestId: undefined,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    set: (state, { payload }: PayloadAction<GetWidgetSettingsResponse>) => {
      state.value = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state, action) => {
      state.currentRequestId = action.meta.requestId;
      state.request = 'loading';
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.request = 'idle';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.request = 'error';
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { reducer: settingsReducer } = settingsSlice;
