import React, { useContext } from 'react';

import { RootState } from '../store';

export const PreloadedStateContext = React.createContext<RootState | undefined>(
  undefined,
);

export const PreloadedStateProvider: React.FC<{
  state?: RootState;
}> = ({ state, children }) => {
  return (
    <PreloadedStateContext.Provider value={state}>
      {children}
    </PreloadedStateContext.Provider>
  );
};

export function usePreloadedState() {
  return useContext(PreloadedStateContext);
}
