import { useEffect } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  expandWidget,
  selectSettings,
  selectShouldAutoOpenWidget,
} from '@/features';
import { useAppDispatch, useAppSelector } from '@/store';

export const useForcedExpandInViewer = () => {
  const dispatch = useAppDispatch();
  const { isEditor, isMobile } = useEnvironment();
  const neverLoadedSettings = useAppSelector(selectSettings.neverLoaded);
  const shouldAutoOpenWidget = useAppSelector(selectShouldAutoOpenWidget);

  useEffect(() => {
    if (isEditor || neverLoadedSettings || isMobile) {
      return;
    }

    if (
      shouldAutoOpenWidget &&
      !JSON.parse(sessionStorage.getItem('forceExpandedInViewer') ?? 'false')
    ) {
      dispatch(expandWidget());
      sessionStorage.setItem('forceExpandedInViewer', 'true');
    }
  }, [isEditor, neverLoadedSettings, shouldAutoOpenWidget, isMobile, dispatch]);
};
