import { createListenerMiddleware } from '@reduxjs/toolkit';

import { layoutSlice, VisualLayoutState } from '@/features';
import { SS_VISUAL_LAYOUT_STATE } from '@/constants';

const listener = createListenerMiddleware();

listener.startListening({
  actionCreator: layoutSlice.actions.setVisual,
  effect: async (action) => {
    switch (action.payload) {
      case VisualLayoutState.Visible:
        sessionStorage.setItem(
          SS_VISUAL_LAYOUT_STATE,
          VisualLayoutState.Visible,
        );
        break;
      case VisualLayoutState.Minimized:
        sessionStorage.setItem(
          SS_VISUAL_LAYOUT_STATE,
          VisualLayoutState.Minimized,
        );
        break;
      default:
        break;
    }
  },
});

export const sessionVisualLayoutMiddleware = listener.middleware;
